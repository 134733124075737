import logo from './logo.svg';
import './App.css';
import axios from 'axios';
import jwt_decode from "jwt-decode";

import {
  NavLink,
  Outlet,
  useSearchParams,
  useNavigate,
  useLocation,
  
} from "react-router-dom";

import { Redirect } from 'react-router';
import { useState, useEffect } from 'react';
import { Cookies, useCookies } from 'react-cookie';
import jwt from 'jsonwebtoken';
import { nanoid } from 'nanoid';

function App() {
  let [searchParams, setSearchParams] = useSearchParams();
  console.log('code >>>', searchParams.get("code"));
  console.log('email >>>', searchParams.get("email"));
  let [email, setEmail] = useState(null);
  let [lastName, setLastName] = useState(null);
  let [firstName, setFirstName] = useState(null);
  let [loading, setLoading] = useState(true);
  let [subscribe, setSubscribe] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(location.hash);
  const arrParams = [...params.entries()];
  const [cookieIdToken, setCookieIdToken] = useCookies(['idToken']);
  const [accessToken, setAccessToken] = useCookies(['accessToken']);
  const aToken = new Cookies().get('accessToken');

  useEffect(() => {
    const user = new Cookies().get('idToken');
    
    // const isIdTokenHash = arrParams.length > 0 &&
    // arrParams[0][0] === '#id_token' &&
    // arrParams[0][1]

    console.log("@@@ aToken", aToken)
    if (aToken) {
      axios.post(`${process.env.REACT_APP_SSO}/me`, new URLSearchParams({
        access_token: aToken
      }), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
        .then((res) => {
          setEmail(res.data.email);
          setFirstName(res.data.name);

        })
        .catch(function (error) {
          console.log(error);
          setLoading(false);
          setSubscribe(false);
        })
    }
    if(!user&& searchParams.get("code")) {
      console.log("@@@ post code")
  
  
  
      axios.post(process.env.REACT_APP_BACKEND, {
        code: searchParams.get("code")
      })
      .then(function (response) {
        console.log("@@@ response", response)
        console.log('data >>>', JSON.stringify(response.data));
        var idTokenData = jwt.decode(response.data.id_token);
        console.log('decode >>>', idTokenData);
        setEmail(idTokenData.email);
        setFirstName(idTokenData.name);
        
        if (idTokenData) {
          setCookieIdToken('idToken', response.data.id_token, {
            expires: idTokenData?.exp ? new Date(idTokenData?.exp * 1000) : null,
          });
          if(response.data.access_token) {
            setAccessToken('accessToken', response.data.access_token, {
              expires: idTokenData?.exp ? new Date(idTokenData?.exp * 1000) : null,
            });
          }
          navigate('/');
        }
  
        // if (response.data.access_token != undefined) {
        //   let decoded = jwt_decode(response.data.access_token);
        //   console.log('decode >>>', decoded);
  
        //   setEmail(decoded.email);
        //   setLastName(decoded.last_name);
        //   setFirstName(decoded.first_name);
        //   setLoading(false);
        //   setSubscribe(true);
        // } else {
        //   setLoading(false);
        //   setSubscribe(false);
        //   //window.location.href = 'https://services.meyzer360.com/';
        // }
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
        setSubscribe(false);
      })
      

    } else if(!user) {
      console.log("@@@ no code no user", `${process.env.REACT_APP_SSO}/auth?redirect_uri=${window.origin}&client_id=${process.env.REACT_APP_CLIENT_ID}&response_type=code&scope=openid%20profile%20email&code_challenge=${process.env.REACT_APP_CODE_CHALLENGE}&code_challenge_method=S256`)
      window.location.href = `${process.env.REACT_APP_SSO}/auth?redirect_uri=${window.origin}&client_id=${process.env.REACT_APP_CLIENT_ID}&response_type=code&scope=openid%20profile%20email&code_challenge=${process.env.REACT_APP_CODE_CHALLENGE}&code_challenge_method=S256&nonce=${nanoid()}`
    }
    // if (!user && isIdTokenHash) {
    //   const idTokenData = jwt.decode(arrParams[0][1]);
    //   setCookieIdToken('idToken', arrParams[0][1], {
    //     expires: idTokenData?.exp ? new Date(idTokenData?.exp * 1000) : null,
    //   });
    //   navigate('/');
    // }

  }, [])

  return (
    <div className="App">
    <header className="App-header">
      <img src={logo} className="App-logo" alt="logo" />
      <p>
        Edit <code>src/App.js</code> and save to reload.
      </p>
      <a
        className="App-link"
        href="https://reactjs.org"
        target="_blank"
        rel="noopener noreferrer"
      >
        Learn React
      </a>
      <p>
        { firstName && `welcome ${firstName}. you are logged in.`}
      </p>
      <p>
        { email && `your email is ${email}`}
      </p>
      <p>
        { !aToken && <>you have no access. click <a href="https://services.meyzer360.com/">here</a> to return.</>}
      </p>
    </header>
    </div>
  )
    

}

export default App;
